import 'wicg-inert';
import Accordion from '../vendor/accordion.js';
import {$, $$} from '../utils/query-selector.js';

const OPTIONS = {
	duration: 200,
	onlyChildNodes: false,
	elementClass: 'js-accordion__item',
	triggerClass: 'js-accordion__trigger',
	panelClass: 'js-accordion__panel',
	showMultiple: true,
};

function getRandomIntInclusive(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

for (const $el of $$('[js-accordion]')) {
	let openIndex = -1;
	let $$elements = $$(`.${OPTIONS.elementClass}`, $el);
	let $$panels = $$(`.${OPTIONS.panelClass}`, $el);

	if (window.location.hash.length > 1) {
		const id = window.location.hash.replace(/^#/, '');
		openIndex = $$elements.findIndex(
			($item) => $item.getAttribute('id') === id,
		);
	}

	if ($el.dataset.accordionOpenRandom) {
		openIndex = getRandomIntInclusive(0, $$elements.length - 1);
	}

	for (let $panel of $$panels) {
		$panel.setAttribute('aria-hidden', 'true');
		$panel.inert = true;
	}

  if (openIndex !== -1) {
    $$panels[openIndex].setAttribute('aria-hidden', 'false')
    $$panels[openIndex].inert = false
  }

	const accordion = new Accordion($el, {
		...OPTIONS,
		openOnInit: openIndex !== -1 ? [openIndex] : [],
		beforeOpen: ($el) => {
      $el.style.setProperty(
				'--accordion--transition-duration',
				`${OPTIONS.duration}ms`,
			);
			const $panel = $(`.${OPTIONS.panelClass}`, $el);
			$panel.setAttribute('aria-hidden', 'false');
			$panel.inert = false;
		},
		onClose: ($el) => {
			const $panel = $(`.${OPTIONS.panelClass}`, $el);
			$panel.setAttribute('aria-hidden', 'true');
			$panel.inert = true;
		},
	});

	if (openIndex !== -1 && !$el.dataset.accordionOpenRandom) {
		// $$elements[openIndex].scrollIntoView({
		// 	behavior: 'auto',
		// 	block: 'start',
		// });
		$$elements[openIndex].setAttribute('tabindex', '-1');
		$$elements[openIndex].focus();
		$$elements[openIndex].addEventListener(
			'focusout',
			function () {
				this.removeAttribute('tabindex');
			},
			{once: true},
		);
	}

	$el.addEventListener('click', (e) => {
		const $link = e.target.closest('a');

		if (!$link) {
			return;
		}

		if (!$link.hash || $link.getAttribute('href').indexOf('#') !== 0) {
			return;
		}

		const $target = $el.querySelector($link.hash);

		if (!$target) {
			return;
		}

		const openIndex = $$elements.findIndex(
			($item) => $item.getAttribute('id') === $link.hash.substring(1),
		);

		if (openIndex === -1) {
			return;
		}

		accordion.open(openIndex);
	});
}
