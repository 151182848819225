import {$, $$} from '../utils/query-selector.js';
import Swiper, {A11y, Keyboard, Mousewheel, FreeMode, Scrollbar} from 'swiper';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';
import 'swiper/css/mousewheel';

class Infographic {
	constructor($el) {
		this.$el = $el;

		this.$swiper = $('[js-infographic__swiper]', this.$el);
		this.$$slides = $$('[js-infographic__slide]', this.$el);

		this.swiper = new Swiper(this.$swiper, {
			modules: [A11y, Keyboard, FreeMode, Mousewheel, Scrollbar],
			loop: false,
			keyboard: true,
			grabCursor: true,
			freeMode: {
				enabled: true,
			},
			slidesPerView: 'auto',
			mousewheel: {
				enabled: true,
				forceToAxis: true,
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
				snapOnRelease: false,
				dragSize: 'auto',
			},
			spaceBetween: 80,
			a11y: {
				enabled: true,
				firstSlideMessage: 'Erste Grafik',
				lastSlideMessage: 'Letzte Grafik',
				nextSlideMessage: 'Nächste Grafik',
				prevSlideMessage: 'Vorherige Grafik',
			},
		});

		// additional handler needed, since Swiper itself does not work
		// when a bullet consists of more than one element
		this.$swiper.addEventListener('click', (e) => {
			const $bullet = e.target.closest('[js-swiper__pagination-bullet]');

			if (!$bullet) {
				return;
			}

			this.swiper.slideToLoop(parseInt($bullet.dataset.index, 10));
			e.stopImmediatePropagation();
		});
	}
}

$$('[js-infographic]').forEach(($el) => new Infographic($el));
